import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import classNames from "classnames";
import Tags from './ReactTagify'
import FontAwesome from "../../utilities/FontAwesome";
import {StoreContext} from "../../../stores/StoreLoader";

@observer
class TextFilter extends Component {
    static contextType = StoreContext;
    static propTypes = {
        withCategoryFilter: PropTypes.bool.isRequired,
        textCategorySearch: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.mapToggleRef = createRef();

        this.keyboardTimer = null;

        this.currentFilterText = null;
        this.currentFilterTextAsTags = new Map();

        this.tagifySettings = {
            callbacks: {
                add     : this.onTagifyAdd,
                remove  : this.onTagifyRemove,
                input   : this.onTagifyInput,
            },
            delimiters: ["@"],
        };
    }

    filterByText = () => {
        const tags = Array.from(this.currentFilterTextAsTags.keys());
        if (this.currentFilterText) {
            tags.push(this.currentFilterText);
        }

        const tagString = tags.join('|');
        this.context.gridStore.textFilter.filterByText(tagString);
    };

    // callbacks for all of Tagify's events:
    onTagifyAdd = e => {
        if (e.detail.data?.alias) {
            // filtering by category search
            this.context.gridStore.categoryFilters.toggleFilter(e.detail.data.id);
        } else if (!e.detail.data?.id) {
            // this is not a category tag that was added by the system, this is related to a text search
            this.currentFilterTextAsTags.set(e.detail.data?.value, true);
            this.currentFilterText = null;  // because the text was added as a tag, we can clear the text
            this.filterByText();
        }
    };

    onTagifyRemove = e => {
        const { gridStore } = this.context;
        const tag = e.detail.data,
            filter = gridStore.categoryFilters.filters.get(tag.id),
            searchFilter = gridStore.categoryFilters?.filters.get(tag.id);

        if (filter && filter.active) {
            gridStore.categoryFilters.toggleFilter(tag.id);
        } else if (searchFilter && searchFilter.active) {
            gridStore.categoryFilters.toggleFilter(tag.id);
        } else if (!filter) {
            this.currentFilterTextAsTags.delete(e.detail.data?.value);
            this.filterByText();
        }
    };

    onTagifyInput = e => {
        // Set Timeout
        clearTimeout(this.keyboardTimer);

        const filterByText = () => {
            this.currentFilterText = e.detail.data?.value;
            this.filterByText();
        };

        if (this.props.textCategorySearch) {
            // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
            // if (!this.controller && AbortController) {
            //     this.controller = new AbortController;
            // } else if (this.controller) {
            //     this.controller.abort();
            // }

            this.keyboardTimer = setTimeout(async () => {
                this.context.gridStore.queryByTextSearch(e.detail.data?.value);
            }, 500);

        } else {
            this.keyboardTimer = setTimeout(() => {
                filterByText();
            }, 300);
        }
    };

    render() {
        const { gridStore } = this.context;

        const mapButtonClassNames = classNames({
            'sb-collection-filter-bar-map': true,
            'active': gridStore.shouldShowMap,
        });

        let mapButton;

        if (gridStore.mapEnabled) {
            mapButton = [
                <div key={3} className="sb-collection-filter-bar-line"/>,
                <div key={4} className={mapButtonClassNames}>
                    <button ref={this.mapToggleRef} onClick={this.props.toggleMap} title="Toggle Map Display">
                        <FontAwesome prefix={'fas'} name={'fa-map-marked-alt'} />
                    </button>
                </div>,
            ]
        }

        return (
            <div className="sb-collection-filter-bar sb-content-width-plus-margin">
                <div className="sb-collection-filter-bar-container">
                     <div className="sb-collection-filter-bar-row">
                         <div key={1} className="sb-collection-filter-bar-search ">
                             <FontAwesome prefix={'fas'} name={'fa-search'} />
                         </div>
                         <div key={2} className="sb-collection-filter-bar-input">
                             <Tags mode='mix'
                                   textCategorySearch={this.props.textCategorySearch}
                                   pattern='@'
                                   autofocus={true}
                                   className=''
                                   placeholder={this.props.textFilterPlaceholder}
                                   name='tags'
                                   settings={this.tagifySettings}
                                   initialValue='' />
                         </div>
                         { mapButton }
                     </div>
                 </div>
            </div>
        );
    }
}

export default TextFilter