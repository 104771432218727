import { Component } from 'react';
import {observer} from "mobx-react";

import CategoryFilter from './CategoryFilter';
import {StoreContext} from "../../../stores/StoreLoader";

@observer
class CategoryFilters extends Component {
    static contextType = StoreContext;

    render() {
        const { gridStore } = this.context;

        const categories = gridStore.categoryFilters.filters;

        return (
            <div style={{margin: '0 auto', textAlign: 'center'}} className="input-group input-group-block">
                {
                    Array.from(categories.values()).map((category) => {
                        if (category.isTopFilter) {
                            return <CategoryFilter key={category.id} category={category} />;
                        }
                    })
                }
            </div>
        );
    }
}

export default CategoryFilters
