import React, {Component, createRef} from 'react';
import PropTypes from 'prop-types';
import {reaction, values} from 'mobx';
import {observer} from 'mobx-react';
import classNames from "classnames";

import ElementSpacer from "../utilities/ElementSpacer";
import Filters from "./geo/Filters";
import InteractiveCollectionMap from '../map/InteractiveCollectionMap';

import Block from "../blocks/SchoolBlocks/_Block";
import {AppContextType, StoreContext} from "../../stores/StoreLoader";
import {columnWidth, gridTypesEnum, renderGoogleLanguage} from "../../utils/SchoolBlocksUtilities";
import {normalizeStringWithoutDiacritics} from "../../utils/InternationalStringUtilities";
import {sb_updateGrid} from "../blocks/SchoolBlocks/blockUtils";
import {ErrorBoundary} from "../utilities/ErrorBoundary";
import styles from "./styles/PackeryGrid.module.scss";
import {isJson} from "../admin/spinup/utilities";
import {StoneMason} from "./packery/StoneMason";
import {Layout, LayoutItem} from "./packery/utils";

let totalAsyncBlocksLoaded = 0;

export const maxBlockGridHeight = 10;
export const maxBlockGridWidth = 4;

export type BlockTypeFilter = {
    id: string,
    title: string
}

type PackeryGridProps = {
    htmlId: string,
    hasMicrosite?: boolean,
    gridType: gridTypesEnum,
    displayMap?: boolean,
    categoryTree?: [],
    tags?: [],
    blockTypeFilters?: BlockTypeFilter[],
    rowHeight: number,
    displayFilterByCategory?: boolean,
    displayFilterByBlockType?: boolean,
    displayFilterByText?: boolean,
    textFilterPlaceholder?: string,
}

@observer
class PackeryGrid extends Component<PackeryGridProps, {}> {
    private gridSelector;
    private locksAreEnabled;
    private tickTotalAsyncBlocksLoaded;
    public gridRef;

    static contextType = StoreContext;

    static propTypes = {
        htmlId: PropTypes.string.isRequired,
        blocks: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
        categoryTree: PropTypes.arrayOf(PropTypes.object),
        blockTypes: PropTypes.object,
        textFilterPlaceholder: PropTypes.string,
        gridType: PropTypes.string.isRequired,
        numAsyncBlocks: PropTypes.number,
        hasMicrosite: PropTypes.bool,
        rowHeight: PropTypes.number.isRequired,
    };

    static defaultProps = {
        blocks: [],
        textFilterPlaceholder: "",
        numAsyncBlocks: 0,
    };

    constructor(props, context) {
        super(props);

        this.gridSelector = '#' + props.htmlId;
        this.locksAreEnabled = this.getLocksAreEnabled(context.interfaceStore.breakpoint);
        this.gridRef = createRef();

        this.buildGrid(context);

        this.handleFinalAsyncBlockLoaded = this.handleFinalAsyncBlockLoaded.bind(this);
    }

    componentDidMount() {
        const {interfaceStore} = this.context as AppContextType;
        this.tickTotalAsyncBlocksLoaded = this.handleFinalAsyncBlockLoaded();

        reaction(() => [
            interfaceStore.breakpoint,
        ], () => {
            this.locksAreEnabled = this.getLocksAreEnabled(interfaceStore.breakpoint);
        });
    }

    buildGrid = context => {
        const {gridStore, organizationStore} = context;
        // turn these JSON blocks to instance of BlockObject

        gridStore.setId(organizationStore.currentOrganization.id);
        gridStore.setGridType(this.props.gridType);

        if (this.props.gridType === gridTypesEnum.ALPHA_GRID) {
            // We only sort the blocks alphabetically when it's an ALPHA_GRID, otherwise we use the block insertion order
            gridStore.setMapEnabled(false);
        } else {
            gridStore.setMapEnabled(this.props.displayMap);
        }

        gridStore.categoryFilters.setFilters(this.props.categoryTree || [], true, true);
        gridStore.tagFilters.setFilters(this.props.tags || [], true);
        gridStore.blockTypeFilters.setFilters(this.props.blockTypeFilters || [], true, true);
    };

    toggleMap = () => {
        const {gridStore} = this.context as AppContextType;
        gridStore.toggleMapVisibility();
    };

    handleFinalAsyncBlockLoaded = () => {
        const {gridStore, i18nStore} = this.context as AppContextType;
        const totalAsyncBlocks = values(gridStore.blocks).filter(b => b.loadAsync).length
        const googCode = i18nStore.googleCode;
        return function () {
            totalAsyncBlocksLoaded++;
            console.debug(`${totalAsyncBlocksLoaded} of ${totalAsyncBlocks} async blocks loaded'`);
            if (totalAsyncBlocksLoaded === totalAsyncBlocks) {
                renderGoogleLanguage(googCode);
            }
        }
    }

    onDragStop = (newLayout: Layout, oldItem: LayoutItem, newItem: LayoutItem, placeholder, e, element) => {
        const {gridStore} = this.context as AppContextType;

        // sort all items according to the new layout
        gridStore.sortBlocksManually(newLayout.map(item => item.i));

        // move the block
        gridStore.relocateBlock(newItem.i, newItem.x, newItem.y);

        sb_updateGrid(gridStore);
    }

    onResizeStop = (newLayout: Layout, oldItem: LayoutItem, newItem: LayoutItem, placeholder, e, element) => {
        const {gridStore} = this.context as AppContextType;

        gridStore.resizeBlock(newItem.i, newItem.w, newItem.h);

        sb_updateGrid(gridStore);
    }

    onDrag = (newLayout: Layout, oldItem:  LayoutItem, newItem: LayoutItem, placeholder, e, element) => {
        const {interfaceStore} = this.context as AppContextType;

        if (this.gridRef.current) {
            const gridRect = this.gridRef.current.getBoundingClientRect();
            const relativeTop = interfaceStore.scrollPosition + gridRect.top;
            if (interfaceStore.scrollPosition > 0 && e.y < relativeTop) {
                window.scroll({
                    top: window.scrollY - 100,
                    behavior: "smooth",
                })
            }
        }
    }

    getMaxColumns = (breakpoint) => {
        switch (breakpoint) {
            case 'break-point-xs':
                return 1;
            case 'break-point-sm':
                return 2;
            case 'break-point-md':
                return this.props.hasMicrosite ? 2 : 3;
            default:
                return this.props.hasMicrosite ? 3 : 4;
        }
    }

    getLocksAreEnabled = (breakpoint) => {
        switch (breakpoint) {
            case 'break-point-xs':
                return false;
            case 'break-point-sm':
                return false;
            case 'break-point-md':
                return false;
            default:
                return true;
        }
    }

    render() {
        const {htmlId, gridType} = this.props;
        const {gridStore, organizationStore, userStore, interfaceStore} = this.context as AppContextType;
        const grid = organizationStore.currentOrganization;
        const maxColumns = this.getMaxColumns(interfaceStore.breakpoint);

        const isSearchingGoogleDrive = organizationStore.organization.json_data?.settings?.identity?.google?.searchDrive;
        const isSearchingOffice365Drive = organizationStore.organization.json_data?.settings?.identity?.office365?.searchDrive;

        const gridClassName = classNames({
            "packery": true,
            [styles.packery]: true,
        });

        const displayFilterByCategory = this.props.displayFilterByCategory;
        const displayFilterByBlockType = this.props.displayFilterByBlockType;
        const displayFilterByText = this.props.displayFilterByText;
        const displayFilters = displayFilterByCategory || displayFilterByBlockType || displayFilterByText;

        const shouldRemoveSectionBlocks = this.props.hasMicrosite && values(gridStore.blocks)
            .filter(block => block.blockType === "section").length > 0;
        const blockObjNames = {};

        return <ErrorBoundary>
            <ElementSpacer/>
            {displayFilters && <Filters gridStore={gridStore}
                                        isSearchingGoogleDrive={isSearchingGoogleDrive}
                                        isSearchingOffice365Drive={isSearchingOffice365Drive}
                                        categoryFilters={displayFilterByCategory}
                                        blockTypeFilters={displayFilterByBlockType}
                                        textFilter={displayFilterByText}
                                        textFilterPlaceholder={this.props.textFilterPlaceholder}
                                        toggleMap={this.toggleMap}/>}
            <InteractiveCollectionMap enabled={this.props.displayMap}/>
            <main id={htmlId} className={gridClassName} role="main"
                  style={{paddingTop: gridType === gridTypesEnum.ALPHA_GRID ? "4rem" : 0}}
                  data-id={grid.id} data-grid-id={grid.id}>
                <StoneMason
                    rowHeight={this.props.rowHeight}
                    width={columnWidth * maxColumns}
                    margin={[14, 14]}
                    useCSSTransforms={false} // important so we don't see blocks transition in on initial client render
                    draggableHandle={".sb-move-icon"}
                    resizeHandles={userStore.editor ? ['se', 's', 'e'] : []}
                    cols={maxColumns}
                    onDragStop={this.onDragStop}
                    onResizeStop={this.onResizeStop}
                    onDrag={this.onDrag}
                    innerRef={this.gridRef}
                >
                    {values(gridStore.blocks)
                        .filter(block => {
                            if (this.props.hasMicrosite && shouldRemoveSectionBlocks) {
                                return block.blockType !== "section";
                            }
                            return gridStore.blockVisibility.get(block.id)?.isVisible;
                        })
                        .map((blockObj) => {
                            let sizeY = blockObj.sizeY, sizeX = Math.min(blockObj.sizeX, maxColumns);
                            if (
                                sizeX === null ||
                                sizeX < 1 ||
                                interfaceStore.breakpoint === "break-point-xs"
                            ) {
                                sizeX = 1;
                            }
                            if (
                                !sizeY ||
                                sizeY < 1 ||
                                interfaceStore.breakpoint === "break-point-xs"
                            ) {
                                // if on small screen, block is bigger than 2, AND it's not a banner/button (defined by blockObj.sizeX > 1), display height of 2
                                // canUseDOM is here because when rehydrating the DOM, sizeY won't update appropriately on the client. breakpoint is always xs on the server
                                let json_data = blockObj.blockModel.json_data;
                                if (typeof json_data === 'string' && isJson(json_data)) json_data = JSON.parse(json_data);

                                if (blockObj.blockType === "message" && json_data.block.settings.items?.[0]?.image &&
                                    !json_data.block.settings.items?.[0].message && blockObj.sizeX > 1) {
                                    // handle banners/buttons
                                    sizeY = 1;
                                } else if (sizeY > 2 && !blockObj.expanded) {
                                    sizeY = 2;
                                }
                            }
                            // if name is not in blockObjNames and not hidden, it's first
                            let isFirst = false;

                            // This is tricky as we internationalize things.  If the first character is a digit, we want it
                            // to fall under our header for '#'.  Other characters (emoji, non-latin alphabet, etc) just just
                            // use the first character and move on.  Except diacritics should be grouped into the same heading
                            // as their regular latin equivalent.  So A and Á both fall under the heading A.
                            const firstCharacter = (blockObj.blockType === 'person' ? blockObj.blockModel.lname?.[0] : blockObj.filterString?.[0])?.toLocaleUpperCase();
                            const firstCharacterIsLetter = firstCharacter ? /[\D]/.test(firstCharacter) : null;
                            const normalizedFirstCharacter = firstCharacterIsLetter ? normalizeStringWithoutDiacritics(firstCharacter).toLocaleUpperCase() : "#";
                            if (!blockObjNames[normalizedFirstCharacter]) {
                                isFirst = true;
                                blockObjNames[normalizedFirstCharacter] = true;
                            }

                            const screenIndependentMaxColumns = this.props.hasMicrosite ? 3 : 4;
                            const isStaticGrid = this.props.gridType !== gridTypesEnum.FLEX_GRID;
                            const shouldBeLockedBasedOnGrid = !isStaticGrid
                                && this.locksAreEnabled
                                && blockObj.positionLocked
                                && maxColumns === screenIndependentMaxColumns
                                && maxColumns >= blockObj.posX + blockObj.sizeX;
                            const positionIsMutable = !isStaticGrid && maxColumns === screenIndependentMaxColumns;

                            return <Block
                                key={blockObj.id}
                                blockObj={blockObj}
                                isFirst={isFirst}
                                gridType={this.props.gridType}
                                locksAreEnabled={this.locksAreEnabled}
                                outsideGrid={false}
                                maxColumns={maxColumns}
                                rowHeight={this.props.rowHeight}
                                normalizedFirstCharacter={normalizedFirstCharacter}
                                sizeX={sizeX}
                                sizeY={sizeY}
                                tickLoadedAsync={this.tickTotalAsyncBlocksLoaded}
                                isBlockPreview={false}
                                hasMicrosite={this.props.hasMicrosite || false}
                                // StoneMason props
                                maxH={maxBlockGridHeight}
                                maxW={maxBlockGridWidth}
                                w={sizeX}
                                h={sizeY}
                                static={shouldBeLockedBasedOnGrid}
                                x={blockObj.posX}
                                y={blockObj.posY}
                                isResizable={positionIsMutable && !shouldBeLockedBasedOnGrid}
                                isDraggable={positionIsMutable && !shouldBeLockedBasedOnGrid}
                            />
                        })}
                </StoneMason>
            </main>
        </ErrorBoundary>;
    }
}

export default PackeryGrid
