import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TextFilter from './TextFilter';
import CategoryFilters from './CategoryFilters';
import BlockTypeFilters from './BlockTypeFilters';
import ElementSpacer from "../../utilities/ElementSpacer";
import {observer} from "mobx-react";
import {StoreContext} from "../../../stores/StoreLoader";
import styles from "./styles/Filters.module.scss"

@observer
class Filters extends Component {
    static propTypes = {
        textFilter: PropTypes.bool,
        categoryFilters: PropTypes.bool,
        tagFilters: PropTypes.bool,
        blockTypes: PropTypes.bool,
        blockTypeFilters: PropTypes.bool,
        isSearchingGoogleDrive: PropTypes.bool,
        isSearchingOffice365Drive: PropTypes.bool,
        textCategorySearch: PropTypes.bool,
        textFilterPlaceholder: PropTypes.string,
    };

    static contextType = StoreContext;

    static defaultProps = {
        textFilter: false,
        categoryFilters: false,
        tagFilters: false,
        blockTypeFilters: false,
        isSearchingGoogleDrive: false,
        isSearchingOffice365Drive: false,
    };

    render() {
        const { textFilter, categoryFilters, tagFilters, blockTypeFilters } = this.props;

        let filterBarHtml = null,
            textFilterHtml = null,
            blockTypeFilterHtml = null,
            categoryFiltersHtml = null;

        if (textFilter || tagFilters) {
            if (textFilter) {
                textFilterHtml = <TextFilter
                    toggleMap={this.props.toggleMap}
                    textCategorySearch={this.props.textCategorySearch}
                    withCategoryFilter={categoryFilters}
                    textFilterPlaceholder={this.props.textFilterPlaceholder}
                />;
            }

            filterBarHtml = textFilterHtml;

        }

        if (categoryFilters) {
            categoryFiltersHtml = <CategoryFilters />;
        }

        if (blockTypeFilters) {
            blockTypeFilterHtml = <BlockTypeFilters
                isSearchingGoogleDrive={this.props.isSearchingGoogleDrive}
                isSearchingOffice365Drive={this.props.isSearchingOffice365Drive} />;
        }

        return (
            <div className={`${styles.filterContainerWrapper} sb-content-width`}>
                <div className={styles.filterContainer}>
                    {filterBarHtml}
                    {categoryFiltersHtml}
                    {this.context.interfaceStore.breakpoint !== 'break-point-xs' && blockTypeFilterHtml}
                    <ElementSpacer/>
                </div>
            </div>
        );
    }
}

export default Filters
