import { createRef, Component } from 'react';
import {observer, PropTypes as MobxPropTypes } from "mobx-react";
import classNames from 'classnames';

import CategoryIcon from '../../map/CategoryIcon';
import TooltipMenu from './TooltipMenu';
import {StoreContext} from "../../../stores/StoreLoader";

@observer
class CategoryFilter extends Component {
    static contextType = StoreContext;
    static propTypes = {
        category: MobxPropTypes.observableObject.isRequired,
    };

    constructor(props) {
        super(props);

        this.buttonRef = createRef();
        this.tooltipMenuRef = createRef();
    }

    onClick = () => {
        const { category } = this.props;
        this.context.gridStore.categoryFilters.toggleFilter(category.id);

        // this is required because the SchoolBlocks CSS handles changing the icon color to gray when the button is
        // not active, not hovering, and not in focus.
        this.buttonRef.current.blur();

        const tooltipMenu = this.tooltipMenuRef.current;
        if (tooltipMenu) {
            tooltipMenu.showMenu();
        }
    };

    childAction = (child) => {
        const { category } = this.props;
        const {gridStore} = this.context;

        // Add the child filter
        gridStore.categoryFilters.toggleFilter(child.id);
        // Remove the parent filter
        gridStore.categoryFilters.toggleFilter(category.id);

        const tooltipMenu = this.tooltipMenuRef.current;
        if (tooltipMenu) {
            tooltipMenu.hideMenu();
        }
    };

    render() {
        const { category } = this.props;

        let buttonClassName,
            buttonStyle;

        buttonClassName = classNames(
            'sb-category-filter-button',
            'sb-filter-button',
            'sb-filter-button-circle',
            {
                'active': category.active,
                'sb-category-filter-button-transparent': true,
            }
        );

        buttonStyle = {
            backgroundColor: "transparent",
        };

        let subCategories;

        if (category.children && category.children.length > 0) {
            const links = category.children.map((child) => {
                return { name: child.category_name, action: this.childAction.bind(this, child) };
            });

            subCategories = <TooltipMenu ref={this.tooltipMenuRef}
                                         title={category.category_name}
                                         links={links}>
            </TooltipMenu>;
        }

        return (
            <span className="sb-filter-button-circles">
                <button type="button" className={buttonClassName} style={buttonStyle}
                        ref={this.buttonRef} onClick={this.onClick} >
                    <CategoryIcon alias={category.alias}/>
                    <span className="sr-only">Filter by {category.category_name}</span>
                </button>
                <br/>
                <span className="sb-category-filter-name" aria-hidden="true">{category.category_name}</span>
                { subCategories }
            </span>
        );
    }
}

export default CategoryFilter
