import classNames from "classnames";

import styles from "./styles/ElementSpacer.module.scss";

const ElementSpacer = () => {
    const spacerClassName = classNames({
        [styles.elementSpacer]: true,
    });
    return <div className={spacerClassName} aria-hidden={true} />
};

export default ElementSpacer;