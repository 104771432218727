import { Component } from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import classNames from 'classnames';
import {StoreContext} from "../../../stores/StoreLoader";

@observer
class BlockTypeFilter extends Component {
    static contextType = StoreContext;
    static propTypes = {
        blockType: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.buttonRef = null;
    }

    onClick = () => {
        const { blockType } = this.props;
        this.context.gridStore.blockTypeFilters.toggleFilter(blockType.id);

        // this is required because the SchoolBlocks CSS handles changing the icon color to gray when the button is
        // not active, not hovering, and not in focus.
        this.buttonRef.blur();
    };

    render() {
        const { blockType } = this.props;

        const buttonClassName = classNames({
            'sb-filter-button': true,
            [`sb-background-color-${blockType.id}`]: true,
            'active': blockType.active,
        });

        return (
            <li>
                <button type="button" ref={(r) => this.buttonRef = r} className={buttonClassName} onClick={this.onClick}>
                    {blockType.title}
                </button>
            </li>
        );
    }
}

export default BlockTypeFilter
