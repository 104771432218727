import { useContext } from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";

import BlockTypeFilter from './BlockTypeFilter';
import {StoreContext} from "../../../stores/StoreLoader";

const BlockTypeFilters = observer(props => {
    const {gridStore} = useContext(StoreContext);
    const { isSearchingGoogleDrive, isSearchingOffice365Drive } = props;

    const blockTypes = gridStore.blockTypeFilters.filters;

    let fileSearchWarning;

    if (isSearchingGoogleDrive || isSearchingOffice365Drive) {
        let vendor;
        if (isSearchingGoogleDrive) {
            vendor = ' Google Drive';
        } else if (isSearchingOffice365Drive) {
            vendor = ' Office365 One Drive';
        }

        fileSearchWarning = <div className="row">
            <div className="col-sm-12 sb-navbar-search-label">
                To make your searching most effective, we've included results from your
                {vendor} account. No one else can see these same results.
            </div>
        </div>
    }

    return (
        <div className="sb-content-width">
            <div className="sb-headerSchool-block">
                <ul className="list-inline center-block" style={{textAlign: 'center'}}>
                    <li key={0}>
                        <span style={{marginLeft: 10}}>Filter results by:</span>
                    </li>
                    <li key={1} className="visible-xs-block">
                        <span/>
                    </li>
                    {
                        Array.from(blockTypes.values()).map((blockType) => {
                            return <BlockTypeFilter key={blockType.id} blockType={blockType} />;
                        })
                    }
                </ul>
                {fileSearchWarning}
            </div>
        </div>
    );
});

BlockTypeFilters.propTypes = {
    isSearchingGoogleDrive: PropTypes.bool,
    isSearchingOffice365Drive: PropTypes.bool,
}

export default BlockTypeFilters;
