import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {inject, observer} from "mobx-react";

import GoogleMap from './GoogleMap';
import CategoryMapMarker from "./CategoryMapMarker";
import {StoreContext} from "../../stores/StoreLoader";


@observer
class InteractiveCollectionMap extends Component {
    static contextType = StoreContext;

    constructor(props) {
        super(props);

        this.mapRef = createRef();
    }

    render() {
        const { gridStore } = this.context;

        let mapComponent, marker, blockModel, category;
        if (gridStore.shouldShowMap) {
            const markers = [];
            gridStore.visibleBlockIds.forEach((blockId) => {
                blockModel = gridStore.blocks.get(blockId).blockModel;
                category = blockModel.all_categories[0];

                blockModel.locations.forEach((location) => {
                    if (location.latitude !== null &&
                        location.latitude !== 0 &&
                        location.longitude !== null &&
                        location.longitude !== 0) {
                        marker = <CategoryMapMarker key={blockId} title={blockModel.title} category={category}
                                                    lat={location.latitude} lng={location.longitude} />;
                        markers.push(marker);
                    }
                });
            });

            mapComponent = <GoogleMap markers={markers} />;
        }

        const mapBoxClassNames = classNames({
            'active': gridStore.shouldShowMap,
        });

        return (
            <div id="sb-organizations-map" ref={this.mapRef} className={mapBoxClassNames}>
                { mapComponent }
            </div>
        );
    }
}

export default InteractiveCollectionMap